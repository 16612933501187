<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete">删除</a-button>
    </div>
    <a-modal
      title="刪除"
      :visible="del.visible"
      :confirm-loading="del.confirmLoading"
      @ok="handleDeleteOk"
      @cancel="handleDeleteCancel"
    >
      <p>{{ del.ModalText }}</p>
    </a-modal>
    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="loadData"
      :alert="true"
      :rowSelection="rowSelection"
      showPagination="auto"
    >
      <span slot="name" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
      </span>
      <span slot="acreage" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text ? `${text} ㎡` : '暂未设置' }}</ellipsis>
      </span>
      <span slot="areaAll" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text ? `${text} ㎡` : '暂未设置' }}</ellipsis>
      </span>
      <span slot="price" slot-scope="text">
        <a-statistic :value="text + ' 万/套'" valueStyle="font-size: 14px;" />
      </span>
      <span slot="unitPrice" slot-scope="text">
        <ellipsis :length="10" tooltip>{{ text ? `${text} 万/套` : '暂未设置' }}</ellipsis>
      </span>
      <span slot="description" slot-scope="text">
        <ellipsis :length="50" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </span>
    </s-table>

    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      :diagrams="diagrams"
      :models="models"
      :saveLoading="saveLoading"
      @cancel="handleCancel"
      @ok="handleOk"
      @emitDiagrams="getDiagrams"
      @emitModels="getModels"
    />
    <step-by-step-modal ref="modal" @ok="handleOk" />
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {
  addRooms,
  getRooms,
  findByHousesIdRooms,
  editRooms,
  delRooms,
  putRoomsDiagrams,
  putRoomsModels
} from '@/api/rooms'
import { THUMBNAIL_MAX, THUMBNAIL_VEDIOCOVER } from '@/common/const'
// import { inHouses } from '@/api/house'

import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateType'

import { uploadOssBatch } from '@/utils/upload'

const columns = [
  {
    title: '户型名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '建面',
    dataIndex: 'acreage',
    scopedSlots: { customRender: 'acreage' }
  },
  {
    title: '套内',
    dataIndex: 'areaAll',
    scopedSlots: { customRender: 'areaAll' }
  },
  {
    title: '朝向',
    dataIndex: 'direction',
    scopedSlots: { customRender: 'direction' }
  },
  {
    title: '用途',
    dataIndex: 'type'
  },
  {
    title: '单价',
    dataIndex: 'unitPrice',
    scopedSlots: { customRender: 'unitPrice' }
  },
  {
    title: '价格',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '户型解读',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal
  },
  data() {
    this.columns = columns
    return {
      noticeTitle: '户型维护',
      // img
      file: [],
      model: [],
      houseId: '',
      houseLink: '',
      // create model
      visible: false,
      confirmLoading: false,
      saveLoading: false,
      mdl: null,
      roomInfo: null,
      diagrams: [],
      models: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 查询参数
      queryParam: {
        keyword: ''
      },
      search: {
        visible: false
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log(parameter, 'parameter')
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          id: this.houseId,
          type: 'room',
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        console.log(requestParameters, 'requestParameters')
        return findByHousesIdRooms(requestParameters).then(res => {
          const result = {
            data: res._embedded.rooms,
            pageNo: res.page.number,
            pageSize: res.page.size,
            totalCount: res.page.totalElements,
            totalPages: res.page.totalPages
          }
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    getDiagrams(data) {
      this.diagrams = data
    },
    getModels(data) {
      this.models = data
    },
    getRoomInfo(id, cb) {
      getRooms(id).then(res => {
        cb(res)
      })
    },
    resetTable(handle, msg = null, type = 'success') {
      const form = this.$refs.createModal.form
      switch (handle) {
        case 'add':
          if (!msg) {
            msg = '添加成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          break
        case 'edit':
          if (!msg) {
            msg = '修改成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          break
        case 'del':
          if (!msg) {
            msg = '删除成功。'
          }
          this.confirmLoading = false
          this.del.visible = false
          break
      }
      this.$refs.table.refresh()
      this.$notification[type]({
        message: this.noticeTitle,
        description: msg
      })
    },
    handleAdd() {
      this.mdl = null
      this.diagrams = []
      this.models = []
      this.visible = true
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            console.log(item, 'item')
            const requestParameters = {
              id: item.id
            }
            delRooms(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      this.del.visible = false
    },
    handleEdit(record) {
      this.visible = true
      this.getRoomInfo(record.id, response => {
        const diagramsList = record.diagrams.map(item => {
          const file = {
            uid: item.id,
            name: item.path.slice(item.path.lastIndexOf('/') + 1),
            status: 'done',
            path: item.path,
            sort: item.sort
          }
          const path = `${this.host}${item.path}`
          if (item.type === 'video/mp4') {
            file.url = `${path}${THUMBNAIL_VEDIOCOVER}`
            file.src = path
          } else {
            file.url = `${path}${THUMBNAIL_MAX}`
          }
          return file
        })
        const modelsList = record.models.map(item => {
          const file = {
            uid: item.id,
            name: item.path.slice(item.path.lastIndexOf('/') + 1),
            status: 'done',
            path: item.path,
            sort: item.sort
          }
          const path = `${this.host}${item.path}`
          if (item.type === 'video/mp4') {
            file.url = `${path}${THUMBNAIL_VEDIOCOVER}`
            file.src = path
          } else {
            file.url = `${path}${THUMBNAIL_MAX}`
          }
          return file
        })
        this.diagrams = diagramsList
        this.models = modelsList
        this.mdl = { ...response }
      })
    },

    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      this.saveLoading = true
      form.validateFields((errors, values) => {
        console.log(values, 'values')
        if (!errors) {
          const requestParameters = { ...values, house: this.houseLink }
          console.log(requestParameters, 'requestParameters')
          console.log('11222')
          if (this.mdl) {
            requestParameters.id = this.mdl.id
            // 修改
            editRooms(requestParameters)
              .then(res1 => {
                this.changeRoomInfo(requestParameters.id, '编辑成功')
              })
              .catch(() => {
                this.confirmLoading = false
                this.saveLoading = false
              })
          } else {
            // 新增
            console.log(requestParameters, 'requestParameters')

            addRooms(requestParameters)
              .then(res1 => {
                this.changeRoomInfo(res1.id)
              })
              .catch(() => {
                this.confirmLoading = false
                this.saveLoading = false
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    changeRoomInfo(id = '', msg = '新建成功') {
      const form = this.$refs.createModal.form
      if (this.diagrams.length !== 0 && this.models.length !== 0) {
        const mediaArr = [
          {
            title: 'diagrams',
            data: this.diagrams
          },
          {
            title: 'models',
            data: this.models
          }
        ]
        this.fileListUpload(id, mediaArr, msg)
      } else if (this.diagrams.length !== 0 && this.models.length === 0) {
        const mediaArr = [
          {
            title: 'diagrams',
            data: this.diagrams
          }
        ]
        this.fileListUpload(id, mediaArr, msg)
      } else if (this.diagrams.length === 0 && this.models.length !== 0) {
        const mediaArr = [
          {
            title: 'models',
            data: this.models
          }
        ]
        this.fileListUpload(id, mediaArr, msg)
      } else {
        this.visible = false
        this.confirmLoading = false
        this.saveLoading = false
        // 重置表单数据
        form.resetFields()
        // 刷新表格
        this.$refs.table.refresh()
        this.$message.success(msg)
      }
    },
    fileListUpload(id, mediaArr, msg, index = 0) {
      const form = this.$refs.createModal.form
      const media = mediaArr[index]
      const data = media.data
      const title = media.title
      console.log(media, title)
      const mediaList = data.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/rooms/img/' + id).then(mediaRes => {
        if (mediaRes) {
          const urls = mediaRes
            .map(item => {
              if (item._links) {
                return item._links.self.href
              } else {
                return this.serial + '/rest/medias/' + item.uid
              }
            })
            .join('\n')
          switch (title) {
            case 'diagrams':
              putRoomsDiagrams(id, urls).then(res2 => {
                if (index + 1 === mediaArr.length) {
                  this.visible = false
                  this.confirmLoading = false
                  this.saveLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                } else {
                  this.fileListUpload(id, mediaArr, msg, index + 1)
                }
              })
              break
            case 'models':
              putRoomsModels(id, urls).then(res2 => {
                if (index + 1 === mediaArr.length) {
                  this.visible = false
                  this.confirmLoading = false
                  this.saveLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                } else {
                  this.fileListUpload(id, mediaArr, msg, index + 1)
                }
              })
              break
          }
        }
      })
    },
    handleCancel() {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
