import request from '@/utils/request'

const roomsApi = {
  // get rooms info
  rooms: '/api/v1/rest/rooms',
  findByKeyword: '/api/v1/rest/rooms/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/rooms/search/findByHouseId'
}

export function rooms (parameter) {
  return request({
    url: roomsApi.rooms + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function getRooms (id) {
  return request({
    url: roomsApi.rooms + `/${id}`,
    method: 'get'
  })
}

export function addRooms (parameter) {
  return request({
    url: roomsApi.rooms,
    method: 'post',
    data: parameter
  })
}

export function editRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

export function putRoomsDiagrams (roomId, data) {
  return request({
    url: roomsApi.rooms + '/' + roomId + '/diagrams',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function putRoomsModels (roomId, data) {
  return request({
    url: roomsApi.rooms + '/' + roomId + '/models',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function delRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'delete'
  })
}

export function findRooms (parameter) {
  return request({
    url: roomsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

export function findByIdRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'get'
  })
}

export function inRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

export function bindRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.roomId}/${parameter.requestName}`,
    method: parameter.method,
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data: parameter.data
  })
}

export function addInRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.requestId}/${parameter.requestName}`,
    method: 'post',
    data: parameter
  })
}

export function editInRooms (parameter) {
  return request({
    url: roomsApi.Rooms + `/${parameter.requestId}/${parameter.requestName}`,
    method: 'patch'
  })
}

export function findByHousesIdRooms (parameter) {
  return request({
    url: roomsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/hal+json'
    }
  })
}
