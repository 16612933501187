<template>
  <a-drawer
    title="楼盘户型"
    class="drawer"
    :width="900"
    :visible="visible"
    :confirmLoading="loading"
    @close="
      () => {
        $emit('cancel')
      }
    "
  >
    <a-spin :spinning="loading">
      <a-card title="户型信息" :bordered="false">
        <a-form :form="form" class="form">
          <a-row class="form-row" :gutter="16">
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="户型名称">
                <a-input
                  placeholder="请输入户型名称"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        { message: '请输入户型名称', whitespace: true },
                        { max: 20, message: '长度不能大于20' }
                      ]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="套内面积（㎡）">
                <a-input-number :min="0" placeholder="请输入套内面积" v-decorator="['areaAll']" />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="价格（万/套）">
                <a-input-number :min="0" placeholder="请输入价格" v-decorator="['price']" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="朝向">
                <a-input
                  placeholder="请输入朝向"
                  v-decorator="[
                    'direction',
                    {
                      rules: [
                        { message: '请输入朝向', whitespace: true },
                        { max: 20, message: '长度不能大于20' }
                      ]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="用途">
                <a-input
                  placeholder="请输入用途"
                  v-decorator="[
                    'type',
                    {
                      rules: [
                        { message: '请输入用途', whitespace: true },
                        { max: areaLimitLength, message: areaLimitText }
                      ]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="建筑面积">
                <a-input-number
                  :min="0"
                  :max="100000000"
                  placeholder="请输入建筑面积"
                  v-decorator="[
                    'acreage'
                  ]"
                />
              </a-form-item>
            </a-col>
            <!-- <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="标签">
                <a-input
                  placeholder="请输入标签"
                  v-decorator="[
                    'labels',
                    {rules: [{message: '请输入标签', whitespace: true}]}
                  ]" />
              </a-form-item>
            </a-col> -->
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="室">
                <a-input-number :min="0" placeholder="请输入卧室数量" v-decorator="['room']" />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="厅">
                <a-input-number :min="0" placeholder="请输入客厅数量" v-decorator="['hall']" />
              </a-form-item>
            </a-col>
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="卫">
                <a-input-number :min="0" placeholder="请输入卫生间数量" v-decorator="['toilet']" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="colSet.lg" :md="colSet.md" :sm="colSet.sm">
              <a-form-item label="户型单价(元/㎡)">
                <a-input-number :min="0" placeholder="请输入户型单价" v-decorator="['unitPrice']" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-card title="户型解读" :bordered="false">
            <a-row class="form-row" :gutter="16">
              <a-col :lg="24" :md="colSet.md" :sm="colSet.sm">
                <a-form-item>
                  <a-input
                    type="textarea"
                    :auto-size="{ minRows: 10, maxRows: 20 }"
                    style="height: 200px;"
                    placeholder="请输入户型解读"
                    v-decorator="[
                      'description',
                      {
                        rules: [
                          { message: '请输入户型解读', whitespace: true },
                          { max: areaLimitLength, message: areaLimitText }
                        ]
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>

          <a-card title="户型图" :bordered="false">
            <div class="clearfix">
              <uploadOss :media="diagrams" :visible="previewVisible" @emitFiles="getDiagramsList"></uploadOss>
            </div>
          </a-card>

          <a-card title="样板间效果图" :bordered="false">
            <div class="clearfix">
              <uploadOss :media="models" :visible="previewVisible_m" @emitFiles="getModelsList"></uploadOss>
            </div>
          </a-card>
        </a-form>
      </a-card>
    </a-spin>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
    >
      <a-button type="primary" :loading="saveLoading" @click="() => { $emit('ok') } ">保存</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'
import { UploadOss } from '@/components'

// 表单字段
const fields = [
  'name',
  'areaAll',
  'price',
  'direction',
  'type',
  'room',
  'hall',
  'toilet',
  'textarea',
  'description',
  'diagrams',
  'models',
  'acreage',
  'unitPrice'
]

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    saveLoading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => {}
    },
    models: {
      type: Array,
      default: () => []
    },
    diagrams: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadOss
  },
  data() {
    return {
      form: this.$form.createForm(this),
      colSet: {
        lg: 8,
        md: 12,
        sm: 24
      },
      // upload
      previewVisible: false,
      previewVisible_m: false

    }
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$message.success('提交成功')
    },
    getDiagramsList(data) {
      this.$emit('emitDiagrams', data)
    },
    getModelsList (data) {
      this.$emit('emitModels', data)
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>

<style lang="less" scoped>
.drawer /deep/ .ant-drawer-body {
  padding: 0l;
}
</style>
